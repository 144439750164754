/* eslint-disable quotes */
import {
  ADDITIONAL_COMMENTS_FIELD,
  ADDRESS,
  ADDRESS_FIELD,
  AMOUNT_FIELD,
  CITY,
  CITY_FIELD,
  COMPANY_BIC,
  COMPANY_IBAN,
  COMPANY_NAME_FIELD,
  COMPANY_ROLE_FIELD,
  COMPANY_SIRET_SIREN,
  CONNECT_CRM_FIELD,
  CONTACT_BIRTH_DATE_FIELD,
  CONTACT_DATE_FIELD,
  CONTRACT_REFERENCE_FIELD,
  COUNTRY,
  COMPANY_ADDRESS,
  COMPANY_CITY,
  COMPANY_COUNTRY,
  COMPANY_PHONE_NUMBER,
  COMPANY_ZIPCODE,
  COUNTRY_FIELD,
  CREATE_ACCOUNT_PASSWORD_FIELD,
  CUSTOMER_REFERENCE_FIELD,
  DATE_OF_EVENT_FIELD,
  COMPANY_EMAIL,
  EMAIL_FIELD,
  EXCLUSIVE_MARKETS_FIELD,
  FACEBOOK_FIELD,
  FIRST_NAME_FIELD,
  HOW_MANY_USERS_FIELD,
  INCENTIVE_MONTHLY_BUDGET_FIELD,
  INTERESTED_IN_FIELD,
  KIND_OF_PROGRAMS_FIELD,
  LAST_NAME_FIELD,
  LINKEDIN_FIELD,
  MAX_AMOUNT_PURCHASE_FIELD,
  MAX_NO_PURCHASE_FIELD,
  MAX_REWARD_THRESHOLD_FIELD,
  MAX_SALES_THRESHOLD_FIELD,
  MAX_SPONSORSHIP_THRESHOLD_FIELD,
  MIN_AMOUNT_PURCHASE_FIELD,
  MIN_NO_PURCHASE_FIELD,
  MIN_REWARD_THRESHOLD_FIELD,
  MIN_SALES_THRESHOLD_FIELD,
  MIN_SPONSORSHIP_THRESHOLD_FIELD,
  MOBILE_NUMBER_FIELD,
  NEED_TRAINING_FIELD,
  NEEDS_SUPER_ADMIN_FIELD,
  NEW_PASSWORD_CONFIRMATION_FIELD,
  NEW_PASSWORD_FIELD,
  NUMBER_OF_EMAILS_PER_USERS_FIELD,
  NUMBER_OF_MEMBERS_FIELD,
  NUMBER_OF_PROGRAMS_FIELD,
  OLD_PASSWORD_FIELD,
  PASSWORD_CONFIRMATION_FIELD,
  PASSWORD_FIELD,
  PAYPAL_LINK_FIELD,
  PHONE_NUMBER_FIELD,
  PLATFORM_IDENTIFIER_FIELD,
  PRODUCT_NAME_FIELD,
  PRODUCT_REFERENCE_FIELD,
  QUANTITY_FIELD,
  REVENUE_REWARD_AMOUNT_FIELD,
  REVENUE_SALES_AMOUNT_FIELD,
  REWARD_AMOUNT_PER_PURCHASE_FIELD,
  REWARD_RATIO_PER_PURCHASE_FIELD,
  SPECIFIC_URL_FIELD,
  SPONSORSHIP_INFO_FIELD,
  START_DATE_FIELD,
  TARGETED_MARKETS_FIELD,
  TIME_OF_SALE_FIELD,
  TITLE_FIELD,
  PHONE_NUMBER,
  TWITTER_FIELD,
  USE_SPECIFIC_APPLICATIONS_FIELD,
  USE_SPECIFIC_MARKET_FIELD,
  WEBSITE_URL_FIELD,
  WHY_PROMOTE_FIELD,
  ZIP_CODE_FIELD,
  ZIPCODE,
  ROLE_IN_COMPANY_FIELD,
  CIVILITY_FIELD,
  POSTAL_FIELD,
  SPONSORSHIP_CIVILITY,
  SPONSORSHIP_EMAIL,
  SPONSORSHIP_PHONE_NUMBER,
  SPONSORSHIP_ADDRESS,
  SPONSORSHIP_ZIPCODE,
  SPONSORSHIP_CITY,
  SPONSORSHIP_FIRSTNAME,
  SPONSORSHIP_LASTNAME,
  DATE_OF_SPONSORSHIP_FIELD,
  SPONSORSHIP_COMPANY_NAME_FIELD
} from 'constants/formDefinitions/genericFields';
import { DISABLED, FLOATING, IS_INLINE, ROUNDED_ELEMENT } from 'constants/forms';
import {
  ADDRESS_INPUT_NON_REQUIRED_CONSTRAINT_GROUP,
  BASE_INPUT_NON_REQUIRED_CONSTRAINT_GROUP,
  DATE_INPUT_NON_REQUIRED_CONSTRAINT_GROUP,
  INPUT_TYPE_CONSTRAINT,
  PAYPAL_ACCOUNT_URL_REGEXP,
  PHONE_NON_REQUIRED_CONSTRAINT_GROUP,
  REQUIRED_CONSTRAINT,
  REQUIRED_TRIMMED_CONSTRAINT,
  SOCIAL_ACCOUNTS_NON_REQUIRED_CONSTRAINT_GROUP,
  SOCIAL_URL,
  STRING,
  ZIP_CODE_NON_REQUIRED_CONSTRAINT_GROUP
} from 'constants/validation';
import { IFormField } from 'interfaces/forms/IForm';
import { IArrayKey } from 'interfaces/IGeneral';

export const CREATE_ACCOUNT_FIELDS: IFormField[] = [
  EMAIL_FIELD,
  CREATE_ACCOUNT_PASSWORD_FIELD,
  PASSWORD_CONFIRMATION_FIELD
];
export const FORGOT_PASSWORD_FIELDS: IFormField[] = [{ ...EMAIL_FIELD, style: ROUNDED_ELEMENT }];
export const PASSWORD_RESET_FORM_FIELDS: IFormField[] = [
  { ...CREATE_ACCOUNT_PASSWORD_FIELD, style: ROUNDED_ELEMENT },
  { ...PASSWORD_CONFIRMATION_FIELD, style: ROUNDED_ELEMENT }
];

export const LOGIN_FORM_FIELDS: IFormField[] = [
  { ...EMAIL_FIELD, style: ROUNDED_ELEMENT },
  { ...PASSWORD_FIELD, style: ROUNDED_ELEMENT, constraints: { ...REQUIRED_CONSTRAINT, ...INPUT_TYPE_CONSTRAINT } }
];

export const CONTACT_FORM_FIELDS: IFormField[] = [
  { ...FIRST_NAME_FIELD, style: ROUNDED_ELEMENT },
  { ...LAST_NAME_FIELD, style: ROUNDED_ELEMENT },
  { ...PHONE_NUMBER_FIELD, style: ROUNDED_ELEMENT },
  { ...INTERESTED_IN_FIELD, style: ROUNDED_ELEMENT },
  { ...INCENTIVE_MONTHLY_BUDGET_FIELD, style: ROUNDED_ELEMENT },
  { ...WEBSITE_URL_FIELD, style: ROUNDED_ELEMENT },
  { ...EMAIL_FIELD, style: ROUNDED_ELEMENT }
];

export const PERSONAL_INFORMATION_FIELDS = [
  TITLE_FIELD,
  LAST_NAME_FIELD,
  FIRST_NAME_FIELD,
  PHONE_NUMBER,
  PLATFORM_IDENTIFIER_FIELD,  
  ADDRESS,
  CITY,
  COUNTRY,
  ZIPCODE,
];

export const PERSONAL_INFORMATION_ADMIN_FIELDS = [
  TITLE_FIELD,
  LAST_NAME_FIELD,
  FIRST_NAME_FIELD,
  COMPANY_NAME_FIELD,
  PHONE_NUMBER,
  COMPANY_PHONE_NUMBER,
  COMPANY_EMAIL,
  COMPANY_ROLE_FIELD,
  ROLE_IN_COMPANY_FIELD,
  COMPANY_IBAN,
  COMPANY_BIC,
  COMPANY_SIRET_SIREN,
  COMPANY_ADDRESS,
  COMPANY_CITY,
  COMPANY_COUNTRY,
  COMPANY_ZIPCODE,  
];

export const CHALLENGE_REVENUE_FIELDS = challenge => {
  const { value, min, max } = challenge;

  return [REVENUE_REWARD_AMOUNT_FIELD(value), MIN_REWARD_THRESHOLD_FIELD(min), MAX_REWARD_THRESHOLD_FIELD(max)];
};

export const CHALLENGE_SALES_FIELDS = challenge => {
  const { value, min, max } = challenge;

  return [REVENUE_SALES_AMOUNT_FIELD(value), MIN_SALES_THRESHOLD_FIELD(min), MAX_SALES_THRESHOLD_FIELD(max)];
};

export const LOYALTY_PURCHASE_AMOUNT_FIELDS = loyalty => {
  const { value, min, max } = loyalty;

  return [REWARD_RATIO_PER_PURCHASE_FIELD(value), MIN_AMOUNT_PURCHASE_FIELD(min), MAX_AMOUNT_PURCHASE_FIELD(max)];
};

export const LOYALTY_PURCHASE_NO_FIELDS = loyalty => {
  const { value, min, max } = loyalty;
  return [REWARD_AMOUNT_PER_PURCHASE_FIELD(value), MIN_NO_PURCHASE_FIELD(min), MAX_NO_PURCHASE_FIELD(max)];
};

export const SPONSORSHIP_FIELDS = sponsorship => {
  const { value, min, max } = sponsorship;

  return [SPONSORSHIP_INFO_FIELD(value), MIN_SPONSORSHIP_THRESHOLD_FIELD(min), MAX_SPONSORSHIP_THRESHOLD_FIELD(max)];
};

export const RESELLER_FORM_FIELDS: IFormField[] = [
  LAST_NAME_FIELD,
  FIRST_NAME_FIELD,
  COMPANY_NAME_FIELD,
  EMAIL_FIELD,
  PHONE_NUMBER_FIELD,
  TARGETED_MARKETS_FIELD,
  EXCLUSIVE_MARKETS_FIELD,
  START_DATE_FIELD,
  WHY_PROMOTE_FIELD
];

export const TAILORED_FORM_FIELDS: IFormField[] = [
  KIND_OF_PROGRAMS_FIELD,
  NUMBER_OF_PROGRAMS_FIELD,
  NUMBER_OF_MEMBERS_FIELD,
  CONNECT_CRM_FIELD,
  HOW_MANY_USERS_FIELD,
  USE_SPECIFIC_MARKET_FIELD,
  NUMBER_OF_EMAILS_PER_USERS_FIELD,
  USE_SPECIFIC_APPLICATIONS_FIELD,
  NEED_TRAINING_FIELD,
  SPECIFIC_URL_FIELD,
  NEEDS_SUPER_ADMIN_FIELD,
  TITLE_FIELD,
  COMPANY_NAME_FIELD,
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  EMAIL_FIELD,
  PHONE_NUMBER_FIELD,
  COMPANY_ROLE_FIELD,
  CONTACT_DATE_FIELD
];

export const GOAL_OPTIONS_FIRST_FORMS = goalOptionValues => {
  return {
    sponsorship: SPONSORSHIP_FIELDS(goalOptionValues),
    loyalty: LOYALTY_PURCHASE_AMOUNT_FIELDS(goalOptionValues),
    challenge: CHALLENGE_REVENUE_FIELDS(goalOptionValues)
  };
};

export const GOAL_OPTIONS_SECOND_FORMS = goalOptionValues => {
  return {
    sponsorship: SPONSORSHIP_FIELDS(goalOptionValues),
    loyalty: LOYALTY_PURCHASE_NO_FIELDS(goalOptionValues),
    challenge: CHALLENGE_SALES_FIELDS(goalOptionValues)
  };
};

export const SIMPLE_ALLOCATION_FORMS = goalOptionValues => {
  return {
    volume: {
      sponsorship: SPONSORSHIP_FIELDS(goalOptionValues),
      loyalty: LOYALTY_PURCHASE_AMOUNT_FIELDS(goalOptionValues),
      challenge: CHALLENGE_REVENUE_FIELDS(goalOptionValues)
    },
    quantity: {
      sponsorship: SPONSORSHIP_FIELDS(goalOptionValues),
      loyalty: LOYALTY_PURCHASE_NO_FIELDS(goalOptionValues),
      challenge: CHALLENGE_SALES_FIELDS(goalOptionValues)
    },
    action: {
      sponsorship: SPONSORSHIP_FIELDS(goalOptionValues),
      loyalty: LOYALTY_PURCHASE_AMOUNT_FIELDS(goalOptionValues),
      challenge: CHALLENGE_REVENUE_FIELDS(goalOptionValues)
    }
  };
};

export const USER_DECLARATION_FIELDS_SPONSORSHIP: IArrayKey<IFormField> = {
  
  dateOfSale: DATE_OF_SPONSORSHIP_FIELD,
  civility: SPONSORSHIP_CIVILITY,
  customerLastName: SPONSORSHIP_LASTNAME,
  customerFirstName: SPONSORSHIP_FIRSTNAME,
  companyName: SPONSORSHIP_COMPANY_NAME_FIELD,
  address: SPONSORSHIP_ADDRESS,
  zipCode: SPONSORSHIP_ZIPCODE,
  city: SPONSORSHIP_CITY,
  phoneNumber: SPONSORSHIP_PHONE_NUMBER,
  email: SPONSORSHIP_EMAIL,
  productName: PRODUCT_NAME_FIELD
  
};

export const USER_DECLARATION_FIELDS_CHALLENGE_ACTION: IArrayKey<IFormField> = {
  dateOfSale: DATE_OF_EVENT_FIELD,
  timeOfSale: TIME_OF_SALE_FIELD,
  companyName: COMPANY_NAME_FIELD,
  productName: PRODUCT_NAME_FIELD,
  productReference: PRODUCT_REFERENCE_FIELD,
  quantity: QUANTITY_FIELD,
  customerReference: CUSTOMER_REFERENCE_FIELD,
  contractReference: CONTRACT_REFERENCE_FIELD,
  additionalComments: ADDITIONAL_COMMENTS_FIELD
};

export const USER_DECLARATION_FIELDS_CHALLENGE: IArrayKey<IFormField> = {
  dateOfSale: DATE_OF_EVENT_FIELD,
  timeOfSale: TIME_OF_SALE_FIELD,
  companyName: COMPANY_NAME_FIELD,
  productName: PRODUCT_NAME_FIELD,
  productReference: PRODUCT_REFERENCE_FIELD,
  quantity: QUANTITY_FIELD,
  amount: AMOUNT_FIELD,
  customerReference: CUSTOMER_REFERENCE_FIELD,
  contractReference: CONTRACT_REFERENCE_FIELD,
  additionalComments: ADDITIONAL_COMMENTS_FIELD,
  
};


export const USER_DECLARATION_FIELDS_PARRAINAGE: IArrayKey<IFormField> = {
  dateOfSponsorship: DATE_OF_EVENT_FIELD,
  timeOfSponsorship: TIME_OF_SALE_FIELD,
  civility: CIVILITY_FIELD,
  lastName: LAST_NAME_FIELD,
  firstName: FIRST_NAME_FIELD,
  address : ADDRESS_FIELD,
  postalCode : POSTAL_FIELD,
  city: CITY_FIELD,
  phone: PHONE_NUMBER_FIELD,
  email: EMAIL_FIELD,
  productName: PRODUCT_NAME_FIELD,
  productReference: PRODUCT_REFERENCE_FIELD
 

};

export const WALL_CREATE_ACCOUNT_FIELDS = [
  { ...PHONE_NUMBER_FIELD, constraints: PHONE_NON_REQUIRED_CONSTRAINT_GROUP },
  { ...MOBILE_NUMBER_FIELD, constraints: PHONE_NON_REQUIRED_CONSTRAINT_GROUP },
  { ...CONTACT_BIRTH_DATE_FIELD, constraints: DATE_INPUT_NON_REQUIRED_CONSTRAINT_GROUP },
  { ...ADDRESS_FIELD, constraints: ADDRESS_INPUT_NON_REQUIRED_CONSTRAINT_GROUP },
  { ...ZIP_CODE_FIELD, constraints: ZIP_CODE_NON_REQUIRED_CONSTRAINT_GROUP },
  { ...CITY_FIELD, constraints: BASE_INPUT_NON_REQUIRED_CONSTRAINT_GROUP },
  { ...COUNTRY_FIELD, constraints: BASE_INPUT_NON_REQUIRED_CONSTRAINT_GROUP },
  {
    ...FACEBOOK_FIELD,
    constraints: { ...SOCIAL_ACCOUNTS_NON_REQUIRED_CONSTRAINT_GROUP }
  },
  {
    ...TWITTER_FIELD,
    constraints: { ...SOCIAL_ACCOUNTS_NON_REQUIRED_CONSTRAINT_GROUP }
  },
  {
    ...LINKEDIN_FIELD,
    constraints: { ...SOCIAL_ACCOUNTS_NON_REQUIRED_CONSTRAINT_GROUP }
  }
 
];

export const WALL_USER_PERSONAL_INFORMATION_FIELDS = [
  { ...TITLE_FIELD, style: { ...IS_INLINE } },
  { ...LAST_NAME_FIELD, style: { ...FLOATING } },
  { ...FIRST_NAME_FIELD, style: { ...FLOATING } }
];

export const PROGRAM_JOIN_USER_FIELDS: IFormField[] = [
  ...WALL_USER_PERSONAL_INFORMATION_FIELDS,
  {
    ...EMAIL_FIELD,
    style: { ...DISABLED, ...FLOATING }
  },
  ...WALL_CREATE_ACCOUNT_FIELDS.map(field => ({
    ...field,
    constraints: {
      ...field.constraints,
      ...(field.constraints.type === STRING ? REQUIRED_TRIMMED_CONSTRAINT : REQUIRED_CONSTRAINT)
    }
  }))
];

export const CHANGE_PASSWORD_FORM_FIELDS: IFormField[] = [
  {
    ...OLD_PASSWORD_FIELD,
    style: ROUNDED_ELEMENT,
    constraints: { ...REQUIRED_CONSTRAINT, ...INPUT_TYPE_CONSTRAINT }
  },
  { ...NEW_PASSWORD_FIELD, style: ROUNDED_ELEMENT, constraints: { ...REQUIRED_CONSTRAINT, ...INPUT_TYPE_CONSTRAINT } },
  { ...NEW_PASSWORD_CONFIRMATION_FIELD, style: ROUNDED_ELEMENT }
];
